import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { EndPoints } from 'src/app/_const/endpoints';
import { UsersType } from 'src/app/_enum/users';
import { UserAttachment } from 'src/app/_models/user-attachment';
import { Users } from 'src/app/_models/users';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private http: HttpClient) { }

  getEndpoint(userType: string) {

    switch (userType) {
      case UsersType.EMPLOYEE:
        return EndPoints.EMPLOYEES;
      case UsersType.CUSTOMER:
        return EndPoints.CUSTOMERS;
      case UsersType.TECHNICIAN:
        return EndPoints.TECHNICIAN;
      default:
        return EndPoints.GET_USERS;
    }
  }

  addAttachment(urls: any, userId: number) {
    return this.http.post<UserAttachment[]>(`${EndPoints.USERS}/${userId}/attachments?type=2`, urls).pipe(
      map((data) => {
        return data;
      }), catchError(error => {
        return throwError(error);
      })
    );
  }

  getUserByType(userid: number, userType: string): Observable<any> {
    return this.http.get<Users>(`${this.getEndpoint(userType)}${userid}`).pipe(
      map((data) => {
        return data;
      }), catchError(error => {
        return throwError(error);
      })
    );
  }

  getUserById(userid: number, userType: string): Observable<any> {
    return this.http.get<Users>(this.getEndpoint(userType) + userid).pipe(
      map((data) => {
        return data;
      }), catchError(error => {
        return throwError(error);
      })
    );
  }
}
