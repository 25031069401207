import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
    name: 'whatsAppTimeAgo'
})
export class WhatsappTimeAgoFormatPipe implements PipeTransform {

    transform(value: string, type: string = '') {
        const today = moment(new Date());
        const givenDate = moment(value);
        const diffInDays = Math.abs(today.diff(givenDate, 'days'));
        if (diffInDays < 8) {
            return this.convertToFormatDate(value, type);
        } else {
            const datePipe = new DatePipe('en-US');
            value = datePipe.transform(value, 'dd-MM-yyyy');
            return value;
        }
    }

    convertToFormatDate(time: string, type) {
        const date = new Date(time);
        const diff = (((new Date()).getTime() - date.getTime()) / 1000);
        const daydiff = Math.floor(diff / 86400);

        if (isNaN(daydiff) || daydiff < 0 || daydiff >= 31) {
            return 'Just Now';
        }
        if (type === 'chat') {
            return daydiff === 0 && 'Today' ||
                daydiff === 1 && 'Yesterday' ||
                daydiff > 1 && this.getDay(time);
        }
        return daydiff === 0 && (
            diff < 60 && 'Just now' ||
            diff < 86400 && moment(time).format('hh:mm a')) ||
            daydiff === 1 && 'Yesterday' ||
            daydiff > 1 && this.getDay(time);
    }

    getDay(time: string) {
        const dayInNUmber = moment(time).day();
        switch (dayInNUmber) {
            case 1:
                return `Monday`;
                break;
            case 2:
                return `Tuesday`;
                break;
            case 3:
                return `Wednesday`;
                break;
            case 4:
                return `Thursday`;
                break;
            case 5:
                return `Friday`;
                break;
            case 6:
                return `Saturday`;
                break;
            case 7:
                return `Sunday`;
                break;

            default:
                break;
        }
    }

}



