import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
    name: 'timeAgo'
})
export class TimeAgoFormatPipe implements PipeTransform {

    transform(value: string) {
        const today = moment(new Date());
        const givenDate = moment(value);
        const diffInDays = Math.abs(today.diff(givenDate, 'days'));
        if (diffInDays < 30) {
            return this.convertToNiceDate(value);
        } else {
            const datePipe = new DatePipe('en-US');
            value = datePipe.transform(value, 'MMM-dd-yyyy');
            return value;
        }
    }

    convertToNiceDate(time: string) {
        const date = new Date(time);
        const diff = (((new Date()).getTime() - date.getTime()) / 1000);
        const daydiff = Math.floor(diff / 86400);

        if (isNaN(daydiff) || daydiff < 0 || daydiff >= 31) {
            return '';
        }

        return daydiff === 0 && (
            diff < 60 && 'Just now' ||
            diff < 120 && '1 minute ago' ||
            diff < 3600 && Math.floor(diff / 60) + ' minutes ago' ||
            diff < 7200 && '1 hour ago' ||
            diff < 86400 && Math.floor(diff / 3600) + ' hours ago') ||
            daydiff === 1 && 'Yesterday' ||
            daydiff < 1 && `${daydiff} days ago` ||
            // daydiff === 3 && `${daydiff} days ago` ||
            // daydiff === 4 && `${daydiff} days ago` ||
            // daydiff === 5 && `${daydiff} days ago` ||
            // daydiff === 6 && `${daydiff} days ago` ||
            // daydiff < 7 && daydiff + ' days ago' ||
            daydiff < 8 && Math.ceil(daydiff / 7) + ' week(s) ago';
    }

}



