import swal from 'sweetalert2';
export default class Alerts {

  constructor() { }


  static successAlert(message) {
     return swal.fire({
        icon: 'success',
        title: message,
        showConfirmButton: false,
        timer: 3500
      });
  }
  static errorAlert(message) {
    return swal.fire({
       icon: 'error',
       title: message,
       showConfirmButton: false,
       timer: 1500
     });
 }

  static warningAlert(message) {
    swal.fire({
        title: 'Are you sure?',
        text: message,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          );
        }
      });
  }
  
  static infoAlert(message: string) {
    return swal.fire({
      icon: 'info',
      title: message,
      showConfirmButton: false,
      timer: 3000,
    });
  }
}

