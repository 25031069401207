import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import Alerts from '../_utils/alert';
import EncryptUtil from '../_utils/encrypt';
import { AuthenticationService } from '../auth.service';
import { ExceptEndpoints } from '../_const/except-endpoints';
import { AuthConst } from '../_const/auth';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const BASE_URL = environment.url;
    const navigator = window.navigator as any;

    const lockScreen = localStorage.getItem(AuthConst.LOCK_SCREEN);
    let lockScreenKey = '';
    if (lockScreen) {
      lockScreenKey = EncryptUtil.decryptString(lockScreen);
    }

    if (navigator.offline) {
      Alerts.errorAlert('Internet is required.');
      return throwError('Internet is required.');
    }

    else if (lockScreenKey === 'true' && !ExceptEndpoints.includes(request.url)) {
      return throwError('Account is Locked');
    }

    else if (ExceptEndpoints.includes(request.url)) {
      request = request.clone({
        url: `${BASE_URL}${request.url}`
      });
    }

    else {
      const isUserLoggedIn = this.authenticationService.isLoggedIn();

      if (isUserLoggedIn) {
        const accessToken = localStorage.getItem(AuthConst.ACCESS_TOKEN);

        request = request.clone({
          url: request.url.includes('https') ? request.url : `${BASE_URL}${request.url}`,
          setHeaders: { 'AR-Token': `${accessToken}` },
        });

      } else {

        request = request.clone({
          url: `${BASE_URL}${request.url}`
        });

      }

    }


    return next.handle(request);
  }
}
