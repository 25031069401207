import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Location } from '@angular/common';
import { IotError } from '../_models/iot-error';
import { HttpClient } from '@angular/common/http';
import { EndPoints } from '../_const/endpoints';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class LoggerService {

  constructor(
    private deviceService: DeviceDetectorService,
    private http: HttpClient,
    private location: Location) { }

  logError(error) {
    const errorObject = new IotError();
    const deviceInfo = this.deviceService.getDeviceInfo();
    errorObject.title = `Filed from ${this.location.path()}`;
    errorObject.description = `Air Restore ${environment.appVersion} @ ${new Date()} | Browser: ${deviceInfo.browser} v${deviceInfo.browser_version} | Device: ${deviceInfo.device} | type: ${deviceInfo.deviceType} in ${deviceInfo.orientation} \n
    OS: ${deviceInfo.os} with ${deviceInfo.os_version} and the useragent is ${deviceInfo.userAgent} \n\n Error Trace: ${error}`;

    if (Object.keys(error).length === 0) {
      // this.createIotIssue(errorObject).subscribe(
      //   (response) => { },
      //   (errorMsg) => { });
    }

  }

  createIotIssue(error: IotError) {
    return this.http.post<IotError>(`${EndPoints.IOT_ERROR}`, error).pipe(
      map((data) => {
        return data;
      }), catchError(errorMsg => {
        return throwError(errorMsg);
      })
    );
  }
}
