import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subscription, of, throwError } from 'rxjs';
import { map, delay, tap, finalize, catchError, combineAll } from 'rxjs/operators';
import { Token } from './_models/token';
import { Login } from './_models/login';
import { EndPoints } from './_const/endpoints';
import { Session } from './_utils/session';
import { RoleService } from './home/masters/manage-role/role.service';
import { RouteConst } from './_const/route';
import SnackBar from './_utils/snackbar';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Constants } from './_const/constants';
import EncryptUtil from './_utils/encrypt';
import { MatDialog } from '@angular/material/dialog';
import { SessionStorageService } from 'angular-web-storage';
import { UserToken } from './_models/user-token';
import { SwPush } from '@angular/service-worker';
import { AuthConst } from './_const/auth';
import { ExceptRoutes } from './_const/except-endpoints';
import { FrameImpl, Stomp } from '@stomp/stompjs';
import * as SockJS from 'sockjs-client';
import { environment } from 'src/environments/environment';
import { ProfileService } from './home/dashboard/profile/profile.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private timer: Subscription;
  private tokens = new BehaviorSubject<Token>(null);
  public redirectUrl: string;
  userToken: Observable<Token> = this.tokens.asObservable();
  callUrl: string;
  currentTime: Date;
  serverTimer: any;
  serverTimerCopy = new BehaviorSubject<any>(null);
  stompClient: any;

  constructor(
    private router: Router,
    private http: HttpClient,
    private appSession: Session,
    private roleService: RoleService,
    public snackBar: MatSnackBar,
    private matDialog: MatDialog,
    public session: SessionStorageService,
    private profileService: ProfileService,
    private route: ActivatedRoute,
    private swPush: SwPush,
  ) { }

  login(login: Login) {
    this.clearLocalStorage();
    login.password = EncryptUtil.baseEncodePassword(login.password);
    return this.http.post<any>(EndPoints.LOGIN, login).pipe(
      map(response => {
        this.initializeUser(response);
      }));
  }

  sendOTP(login: Login) {
    //this.clearLocalStorage();
    //login.password = EncryptUtil.baseEncodePassword(login.password);

    return this.http.post(EndPoints.SEND_OTP, login, { responseType: 'text' }).pipe(
      map((data) => {
        return data;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  verifyOTP(login: Login) { 
    return this.http.put<any>(EndPoints.VERIFY_OTP, login).pipe(
      map((data) => {
        return data;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  resendOTP(login: Login) {   
    return this.http.post(EndPoints.RESEND_OTP, login, { responseType: 'text' }).pipe(
      map((data) => {
        return data;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public initializeUser(response) {
    localStorage.removeItem(Constants.LOGIN_DATA);

    localStorage.setItem(AuthConst.ACCESS_TOKEN, response.accessToken);
    
    this.getRole(response);
  }

  private getRole(response) {
    this.roleService.getProfile().subscribe(profileResponse => {
      const profile = profileResponse;
      this.appSession.getProfile();
      if (response.forceChangePass == 1) {
        this.router.navigate([RouteConst.SET_PASSWORD]);
      } else if (this.redirectUrl) {
        this.router.navigate([this.redirectUrl]);
        this.redirectUrl = null;
      } else if (this.callUrl !== undefined) {
        this.router.navigate([this.callUrl]);
      } else {
        this.router.navigate([RouteConst.APP_DASHBOARD]);
      }
    }, error => SnackBar.open(this.snackBar, error, Constants.ERROR, Constants.ERROR));
  }


  logout() {
    this.http.post(EndPoints.LOGOUT, null, { responseType: 'text' }).pipe(
      finalize(() => {
        this.clearLocalStorage();
        this.router.navigate(['']);
      }),
      tap(() => this.matDialog.closeAll())
    ).subscribe();
  }

  clearLocalStorage() {
    localStorage.clear();
    sessionStorage.clear();
  }

  appInitializeCheckUp() {
    const accessToken = localStorage.getItem(AuthConst.ACCESS_TOKEN);
    const loginData = localStorage.getItem(Constants.LOGIN_DATA);

    if (!accessToken && !loginData) {
      this.clearLocalStorage();
    }

    return of(null);
  }

  isLoggedIn(): boolean {
    const accessToken = localStorage.getItem(AuthConst.ACCESS_TOKEN);

    if (accessToken) {
      return true;
    } else {
      this.clearLocalStorage();
      return false;
    }
  }
}
