import { DatePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "daysFormatPipe",
})

export class DaysFormatPipe implements PipeTransform {
  transform(date) {
    const dateFormattedAsIST = new Date(date);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    if (
      dateFormattedAsIST.getFullYear() === today.getFullYear() &&
      dateFormattedAsIST.getMonth() === today.getMonth() &&
      dateFormattedAsIST.getDate() === today.getDate()
    ) {
      const time = new DatePipe("en-US").transform(dateFormattedAsIST, "shortTime");
      return `Today, ${time}`;
    }

    if (
      dateFormattedAsIST.getFullYear() === yesterday.getFullYear() &&
      dateFormattedAsIST.getMonth() === yesterday.getMonth() &&
      dateFormattedAsIST.getDate() === yesterday.getDate()
    ) {
      return "Yesterday";
    }

    return new DatePipe("en-US").transform(dateFormattedAsIST, 'mediumDate');
  }
}
