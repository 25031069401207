export const EndPoints = {
    LOGIN: '/auth/login',
    LOGOUT: '/auth/logout',
    SEND_OTP: '/auth/send-otp',
    VERIFY_OTP: '/auth/verify-otp',
    RESEND_OTP: '/auth/resend-otp',

    JOBS: '/services',
    JOBS_REPORT: '/services/report',
    JOBS_SAVE_IMAGES: '/services/save',
    REPEAT_SCHEDULE: '/services/repeat-schedule',
    SCHEDULE:'/services/schedule',

    FORGOT_PASSWORD: '/auth/forgot-password',
    CHANGE_PASSWORD: '/auth/change-password/',
    UPDATE_PASSWORD: '/auth/update-password/',

    USERS: '/users',
    GET_USERS: '/users/',
    APPROVE_PARTNER: '/partner/approve/',
    CONVERT_TO_PARTNER: '/users/convert/partner',
    PARTNER_TYPES: '/partners/types',
    EMPLOYEES: '/employees/',
    PARTNERS: '/partners/',
    CUSTOMERS: '/customers/',
    TRANSPORTERS: '/transporters/',
    VENDORS: '/vendors/',
    TYPES: '/%s/types',

    FILE_DELETE: '/files/delete/',
    FILES_SAVE: '/files/save',
    FILES_UPLOAD: '/files/upload',
    FILES_SAVE_WHATSAPP: '/files/whatsapp/',

    ROLES: '/master/roles/',
    PROFILE: '/users/profile/',
    MENU_ROLE: '/master/menu-role/',

    CONFIG_ALL: '/config/all',
    CONFIG: '/config/',

    SCHEDULER: '/scheduler/',
    SCHEDULER_HISTORY: '/scheduler/history/',
    unSubscribe: '/auth/notify/unsubscribe',
    FILE_SIGNED_URL: '/files/signed-url',

    TECHNICIAN: '/technicians/',

    TECHNICIAN_SERVICES: '/technicians/services',
    USER_NAMES: '/users/names',

    // Error

    IOT_ERROR: '/analytics/error',
    APP_INFO: '/app/info',
    AUTH_RENEW: '/auth/renew',
    AUTH_CHANGE_PASSWORD: '/auth/change-password',
};
