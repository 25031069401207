import { AuthenticationService } from './auth.service';

export function appInitializer(authService: AuthenticationService) {
  return () =>
    new Promise<void>((resolve) => {
      authService.appInitializeCheckUp().subscribe((response => {
        setTimeout(() => {
          resolve();
        }, 3000);
      }));
    });
}
