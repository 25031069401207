import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Role } from 'src/app/_models/role';
import { Profile } from 'src/app/_models/profile';
import { EndPoints } from 'src/app/_const/endpoints';


@Injectable({
  providedIn: 'root'
})
export class RoleService {
  profile: Profile;
  constructor(private http: HttpClient) { }


  getRoleList(): Observable<any> {
    return this.http.get<[]>(EndPoints.ROLES).pipe(
      map(data => data), catchError(error => throwError(error))
    );
  }
  createRole(role: Role): Observable<any> {
    return this.http.post(EndPoints.ROLES, role, { responseType: 'text' }).pipe(
      map(data => data), catchError(error => throwError(error))
    );
  }

  updateRole(role: Role): Observable<any> {
    return this.http.put(EndPoints.ROLES, role, { responseType: 'text' }).pipe(
      map(data => data), catchError(error => throwError(error))
    );
  }

  getProfile(): Observable<any> {
    return this.http.get<Profile>(EndPoints.PROFILE).pipe(
      map(data => {
        this.profile = data;
        return this.profile;
      }), catchError(error => throwError(error))
    );
  }
}
