import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'formatText'
})
export class FormatTextPipe implements PipeTransform {

    constructor(private domSanitizer: DomSanitizer) { }

    transform(text: string, type: string = 'text'): any {
        const urlRegex = /((http|https):\/\/)(www.)?[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?/gi;
        const urlFormattedText = text.replace(urlRegex, (url) => {
            return `<a href="${url}" target="_blank">${url}</a>`;
        });

        const asteriskRegex = /[\W|\n]\*(.*?)\*/g;
        let asteriskFormattedText = urlFormattedText.replace(asteriskRegex, (text) => {
            return `<strong>${text.replace(/\*/g, '')}</strong>`;
        });
        

        if (type !== 'file') {
            const mobileRegex = /[\s](?:[-+() ]*\d){10,13}/gm;
            asteriskFormattedText = asteriskFormattedText.replace(mobileRegex, (mobileNumber) => {
                return `<a href="tel:${mobileNumber}" target="_blank">${mobileNumber}</a>`;
            });
        }

        const emailRegex = /[\w\.]+\@[\w]+(?:\.[\w]{3}|\.[\w]{2}\.[\w]{2})\b/g;
        const emailFormattedText = asteriskFormattedText.replace(emailRegex, (email) => {
            return `<a href="mailto:${email}" target="_blank">${email}</a>`;
        });
        
        const underscoreRegex = /[\W|\r\n]_(.*?)\_[\W]*$/gm;
        const italicFormattedText = emailFormattedText.replace(underscoreRegex, (underscoreText) => {
            return `<i>${underscoreText.replace(/_/g, '')}</i>`;
        });
        const tildeRegex = /\~(.*?)\~/g;
        const strikeFormattedText = italicFormattedText.replace(tildeRegex, (tildeText) => {
            return `<strike>${tildeText.replace(/~/g, '')}</strike>`;
        });
        const codeRegex = /\```(.*?)\```/g;
        const codeFormattedText = strikeFormattedText.replace(codeRegex, (codeText) => {
            return `<tt>${codeText.replace(/```/g, '')}</tt>`;
        });

        const formattedMsg = this.domSanitizer.bypassSecurityTrustHtml(codeFormattedText);
        return formattedMsg;
    }

}
